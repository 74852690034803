/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button } from '@arcflight/tf-component-library';
import { Defect } from '../../../models/defects';
import { DashboardState } from '../../../models';
import getOperatorSetting from '../../../utils/getOperatorSetting';
import melChapters from '../../../assets/melChapters.json';
import padlockIcon from '../../../assets/icon-lock-blue.svg';
import TFDropdown from '../../TFDropdown/TFDropdown';
import TFInput from '../../TFInput/TFInput';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import DeferUntil from './DeferUntil';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import RectificationLimits from './RectificationLimits';
import { createMELChapters, getNewDateDueBasedOnInterval, handleDeferralTimeChange } from './utils';

interface ManuallyEnterMELDetailsProps {
  defect: Defect;
  // eslint-disable-next-line react/no-unused-prop-types
  setDateDue: (input) => void;
  noMEL?: boolean;
  apuInstalled?: boolean;
  updateDefectData: (changes: any[]) => void;
  aircraftId?: string;
  formChanged: boolean;
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-left: 20px;
  margin-bottom: 4px;
  margin-top: 20px;
`;

const StyledIcon = styled.img`
  margin-right: 4px;
  margin-bottom: 2px;
  width: 18px;
  margin-left: -3px;
`;

const FlexDiv = styled.div`
  width: 460px;
  margin-top: 20px;
  @media (max-width: 450px) {
    width: 300px;
  }
`;

const DropdownWrapper = styled.div`
  width: ${({ width }): string => (width ? `${width}` : 'auto')};
  margin-right: ${({ marginRight }): string => (marginRight ? `${marginRight}` : '0')};
  margin-top: 20px;
`;

const InputWrapper = styled.div`
  width: 300px;
  margin-top: 20px;
`;

const ManuallyEnterMELDetails: React.FC<ManuallyEnterMELDetailsProps> = ({
  defect,
  noMEL,
  apuInstalled,
  updateDefectData,
  aircraftId,
  formChanged,
}) => {
  const { formatMessage } = useIntl();

  const {
    aircraft: { aircraftMap },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();

  const melNameOverride = aircraftMap.get(aircraftId || id)?.standard_fields?.deferral_type_mel?.name_override;

  const poShortName = getOperatorSetting('po_short_name', id);
  const poSuffix = getOperatorSetting('po_suffix', id);

  const [showRecticationLimits, setShowRectificationLimits] = useState(false);
  const [MELChapters, setMELChapters] = useState([]);
  const [MELSections, setMELSections] = useState([]);
  const [unlockDeferralTime, setUnlockDeferralTime] = useState(false);

  const updateDisplayData = (value: any, key: string): void => {
    const newDisplayData = {
      ...defect?.display_data,
      [`${key}`]: value,
    };
    if (key === 'ata') {
      newDisplayData.ata_section = 0;
    }
    updateDefectData([{ value: newDisplayData, key: 'display_data' }]);
  };

  const handleSelectChange = (option: { title: string }): void => {
    const newDateDue = getNewDateDueBasedOnInterval(option.title, defect.raised_at);
    if (option.title === 'A') {
      updateDefectData([
        { value: option.title, key: 'rectification_category' },
        { value: option.title, key: 'display_data.category' },
        { value: newDateDue, key: 'date_due' },
      ]);
    } else {
      updateDefectData([
        { value: option.title, key: 'rectification_category' },
        { value: option.title, key: 'display_data.category' },
        { value: newDateDue, key: 'date_due' },
        { value: null, key: 'flight_seconds_limit' },
        { value: null, key: 'apu_seconds_limit' },
        { value: null, key: 'flight_days_limit' },
        { value: null, key: 'seconds_limit' },
        { value: null, key: 'calendar_days_limit' },
        { value: null, key: 'cycles_limit' },
        { value: null, key: 'flights_limit' },
        { value: null, key: 'other_limit' },
      ]);
    }
  };

  const handleChapterChange = (option): void => {
    updateDisplayData(option.value, 'ata');
  };

  const handleSectionChange = (option): void => {
    updateDisplayData(parseInt(option.value, 10), 'ata_section');
  };

  const handleReferenceChange = (value: string): void => {
    updateDefectData([{ value, key: 'reference' }]);
  };

  useEffect(() => {
    if (melChapters) {
      const chapters = createMELChapters(melChapters);
      setMELChapters(chapters);
    }
  }, [defect?.display_data?.ata]);

  useEffect(() => {
    if (MELChapters && defect?.display_data?.ata) {
      if (MELChapters.find((chap) => chap.value === defect?.display_data?.ata)) {
        const sections = MELChapters.find((chap) => chap.value === defect?.display_data?.ata)?.sections;
        if (sections?.length) {
          setMELSections(sections);
        }
      }
    }
  }, [defect?.display_data?.ata, MELChapters]);

  useEffect(() => {
    if (defect?.display_data?.category === 'A') {
      setShowRectificationLimits(true);
    } else {
      setShowRectificationLimits(false);
    }
  }, [defect?.display_data?.category]);

  const isMobile = window.innerWidth < 451;

  return (
    <div data-testid="ManuallyEnterMELDetails--Wrapper">
      {defect?.defect_type === 'MEL' && (
        <>
          {noMEL && defect?.deferred ? (
            <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
              {`This aircraft has no ${melNameOverride || 'MEL'} currently, please manually enter the ${
                melNameOverride || 'MEL'
              } details below.`}
            </Label>
          ) : (
            <Label marginBottom={30} color="rgba(36, 45, 65, 0.7)">
              {formatMessage({ id: 'text.pleaseEnterReferenceNumber' }, { po: poShortName, suffix: poSuffix })}
            </Label>
          )}
        </>
      )}
      <InputWrapper>
        <TFInput
          id="reference"
          value={defect?.reference || ''}
          onChange={(e): void => handleReferenceChange(e.target.value)}
          label={formatMessage({ id: 'title.reference' })}
        />
      </InputWrapper>
      {MELChapters ? (
        <FlexDiv>
          <TFDropdown
            options={MELChapters}
            initialValue={
              defect?.display_data?.ata ? MELChapters.find((chap) => chap.value === defect?.display_data?.ata) : null
            }
            onSelect={handleChapterChange}
            label={formatMessage({ id: 'title.ataChapter' })}
            id="ATAChapter"
            searchable
          />
        </FlexDiv>
      ) : null}
      <FlexWrapper column>
        <FlexWrapper column={isMobile}>
          <DropdownWrapper width="300px" marginRight="20px">
            <TFDropdown
              options={MELSections}
              initialValue={
                defect?.display_data?.ata_section || defect?.display_data?.ata_section === 0
                  ? MELSections.find((section) => section.value === defect?.display_data?.ata_section?.toString())
                  : null
              }
              onSelect={handleSectionChange}
              label={formatMessage({ id: 'title.ataSection' })}
              id="ATASection"
            />
          </DropdownWrapper>
          {defect?.deferred && (
            <DropdownWrapper width="140px" marginRight="20px">
              <TFDropdown
                initialValue={{
                  title: defect?.display_data?.category,
                  value: defect?.display_data?.category,
                }}
                options={[
                  { title: 'A', value: 'A' },
                  { title: 'B', value: 'B' },
                  { title: 'C', value: 'C' },
                  { title: 'D', value: 'D' },
                  { title: 'Advisory only', value: 'Advisory only' },
                ]}
                onSelect={handleSelectChange}
                id="RectificationCategory"
                label={formatMessage({ id: 'title.rectificationCategory' })}
              />
            </DropdownWrapper>
          )}
        </FlexWrapper>
        {defect?.deferred && (
          <FlexWrapper marginTop={20} column={isMobile} width="100%">
            <FlexWrapper marginRight={20}>
              <DateTimePicker
                dateTime={defect?.deferred_at}
                headerDate="Deferral date"
                headerTime="Deferral time"
                handleDateTimeChange={(value) => handleDeferralTimeChange(value, defect, updateDefectData)}
                disabled={!unlockDeferralTime}
              />
            </FlexWrapper>
            <FlexWrapper marginTop={isMobile ? 20 : 0} width="auto">
              <DeferUntil defect={defect} formChanged={formChanged} />
            </FlexWrapper>
            <ButtonWrapper>
              {!unlockDeferralTime ? (
                <Button
                  primary={false}
                  height="24px"
                  padding="0 12px"
                  onClick={(): void => setUnlockDeferralTime(true)}
                >
                  <StyledIcon src={padlockIcon} alt="padlock icon" />
                  Unlock
                </Button>
              ) : null}
            </ButtonWrapper>
          </FlexWrapper>
        )}
      </FlexWrapper>
      {showRecticationLimits && defect?.deferred ? (
        <RectificationLimits defect={defect} apuInstalled={apuInstalled} updateDefectData={updateDefectData} />
      ) : null}
    </div>
  );
};

export default ManuallyEnterMELDetails;
