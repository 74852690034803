import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Search } from '@arcflight/tf-component-library';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import Loading from '../../TFLoading/index';
import TFTooltip from '../../TFTooltip/TFTooltip';
import { Defect } from '../../../models/defects';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import TFRadioInput from '../../TFRadioInput/TFRadioInput';
import padlockIcon from '../../../assets/icon-lock-blue.svg';
import getAircraftStandardField from '../../../utils/getAircraftStandardField';
import useQueryGetMELItems from '../../../services/hooks/book_items/useQueryMelItems';
import ViewMelDetails from './ViewMelDetails';
import Card from './Card';
import FlexWrapper from './FlexWrapper';
import Label from './Label';
import DisplayBookItems from './DisplayBookItems';
import DeferUntil from './DeferUntil';
import ManuallyEnterMELDetails from './ManuallyEnterMELDetails';
import ViewNewLayoutMelDetails from './ViewNewLayoutMelDetails';
import { handleDeferralTimeChange } from './utils';

interface DeferralOptionsProps {
  defect: Defect | null;
  updateDefectData: (changes: any[]) => void;
  aircraftId?: string;
  melItemsLoading?: boolean;
  setMelItemsLoading?: (input: boolean) => void;
  setDateDue?: (input) => void;
  apuInstalled?: boolean;
  setAircraftHasMEL?: (value: boolean) => void;
  formChanged?: boolean;
}

const Wrapper = styled.div`
  padding-top: 20px;
`;

const MelItemsWrapper = styled.div`
  position: relative;
`;

const SearchWrapper = styled.div`
  margin-bottom: 20px;
  div,
  img,
  button,
  input {
    box-sizing: revert;
    line-height: normal;
  }
`;

const BoldDiv = styled.div`
  font-weight: 600;
  @media (max-width: 451px) {
    max-width: 165px;
  }
`;

const RegularText = styled.span`
  font-weight: 400;
  margin-left: 4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: 20px;
  margin-bottom: 4px;
  width: 100%;
  @media (max-width: 450px) {
    margin: 20px 0;
  }
`;

const StyledIcon = styled.img`
  margin-right: 4px;
  margin-bottom: 2px;
  width: 18px;
`;

const DeferralOptions: React.FC<DeferralOptionsProps> = ({
  defect,
  aircraftId,
  melItemsLoading,
  setMelItemsLoading,
  setDateDue,
  apuInstalled,
  updateDefectData,
  setAircraftHasMEL,
  formChanged,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [bookItems, setBookItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState(null);
  const [unlockDeferralTime, setUnlockDeferralTime] = useState(false);

  const { formatMessage } = useIntl();

  const isMobile = window.innerWidth < 451;

  const { data: melItems, isFetching: isMelItemsLoading } = useQueryGetMELItems({
    payload: { id: aircraftId },
    refetchOnWindowFocus: false,
    staleTime: 30000,
    enabled: defect?.defect_type === 'MEL',
  });

  const { id } = useParams<{ id: string }>();

  const handleDefectTypeChange = (option): void => {
    const updateArray = [
      { value: option, key: 'defect_type' },
      { value: undefined, key: 'mel_item' },
      { value: undefined, key: 'mel_rectification_id' },
      { value: undefined, key: 'date_due' },
    ];
    if (option === 'NEF') {
      updateArray.push({ value: { category: 'Advisory only' }, key: 'display_data' });
      updateDefectData(updateArray);
    } else {
      updateArray.push({ value: {}, key: 'display_data' });
      updateDefectData(updateArray);
    }
  };

  const showRichTextMel = getAircraftStandardField('rich_text_mel', aircraftId || id)?.enabled;
  const melNameOverride = getAircraftStandardField('deferral_type_mel', aircraftId || id)?.name_override;
  const melLongNameOverride = getAircraftStandardField('deferral_type_mel', aircraftId || id)?.long_name_override;
  const melTypeDisabled = getAircraftStandardField('deferral_type_mel', aircraftId || id)?.enabled === false;
  const cdlNameOverride = getAircraftStandardField('deferral_type_cdl', aircraftId || id)?.name_override;
  const cdlLongNameOverride = getAircraftStandardField('deferral_type_cdl', aircraftId || id)?.long_name_override;
  const cdlTypeDisabled = getAircraftStandardField('deferral_type_cdl', aircraftId || id)?.enabled === false;
  const nefNameOverride = getAircraftStandardField('deferral_type_nef', aircraftId || id)?.name_override;
  const nefLongNameOverride = getAircraftStandardField('deferral_type_nef', aircraftId || id)?.long_name_override;
  const nefTypeDisabled = getAircraftStandardField('deferral_type_nef', aircraftId || id)?.enabled === false;
  const casNameOverride = getAircraftStandardField('deferral_type_cas', aircraftId || id)?.name_override;
  const casLongNameOverride = getAircraftStandardField('deferral_type_cas', aircraftId || id)?.long_name_override;
  const casTypeDisabled = getAircraftStandardField('deferral_type_cas', aircraftId || id)?.enabled === false;
  const otherNameOverride = getAircraftStandardField('deferral_type_other', aircraftId || id)?.name_override;
  const otherTypeDisabled = getAircraftStandardField('deferral_type_other', aircraftId || id)?.enabled === false;

  const deferralOptionsArray = [];
  if (!melTypeDisabled) deferralOptionsArray.push({ title: melNameOverride || 'MEL', value: 'MEL' });
  if (!cdlTypeDisabled) deferralOptionsArray.push({ title: cdlNameOverride || 'CDL', value: 'CDL' });
  if (!nefTypeDisabled) deferralOptionsArray.push({ title: nefNameOverride || 'NEF', value: 'NEF' });
  if (!casTypeDisabled) deferralOptionsArray.push({ title: casNameOverride || 'CAS', value: 'CAS' });
  if (!otherTypeDisabled) deferralOptionsArray.push({ title: otherNameOverride || 'Other', value: 'Other' });

  const buildDeferralOptionsRadioButtons = () => (
    <TFRadioInput
      options={deferralOptionsArray}
      onChange={handleDefectTypeChange}
      value={defect?.defect_type}
      id="DefectType"
    />
  );

  const handleLocalMelItemChange = (melItemId: string, rectId: string): void => {
    const melItem = melItems.find((item) => item.id === melItemId);
    updateDefectData([
      { value: melItem, key: 'melItem' },
      { value: melItem.mel_rectifications[0].operational_limitations, key: 'limitations' },
      { value: rectId, key: 'mel_rectification_id' },
    ]);
  };

  const buildTooltipText = () => {
    const deferralTypes = [
      {
        type: 'MEL',
        nameOverride: melNameOverride,
        longNameOverride: melLongNameOverride || formatMessage({ id: 'text.minimumEquipmentList' }),
        disabled: melTypeDisabled,
      },
      {
        type: 'CDL',
        nameOverride: cdlNameOverride,
        longNameOverride: cdlLongNameOverride || formatMessage({ id: 'text.configurationDeviationList' }),
        disabled: cdlTypeDisabled,
      },
      {
        type: 'NEF',
        nameOverride: nefNameOverride,
        longNameOverride: nefLongNameOverride || formatMessage({ id: 'text.nonEssentialEquipmentFurnishing' }),
        disabled: nefTypeDisabled,
      },
      {
        type: 'CAS',
        nameOverride: casNameOverride,
        longNameOverride: casLongNameOverride || formatMessage({ id: 'text.crewAlertingSystem' }),
        disabled: casTypeDisabled,
      },
    ];

    const filteredDeferralTypes = deferralTypes.filter((type) => !type.disabled);

    return filteredDeferralTypes.map((type) => {
      return (
        <BoldDiv key={type.type}>
          {type.nameOverride}
          <RegularText>{type.longNameOverride}</RegularText>
        </BoldDiv>
      );
    });
  };

  const displayDefectTypeLabel = () => {
    const type = defect?.defect_type;
    let displayText = '';
    switch (type) {
      case 'MEL':
        displayText = `${melNameOverride || 'MEL'} - ${
          melLongNameOverride || formatMessage({ id: 'text.minimumEquipmentList' })
        }`;
        break;
      case 'CDL':
        displayText = `${cdlNameOverride || 'CDL'} - ${
          cdlLongNameOverride || formatMessage({ id: 'text.configurationDeviationList' })
        }`;
        break;
      case 'NEF':
        displayText = `${nefNameOverride || 'NEF'} - ${
          nefLongNameOverride || formatMessage({ id: 'text.nonEssentialEquipmentFurnishing' })
        }`;
        break;
      case 'CAS':
        displayText = `${casNameOverride || 'CAS'} - ${
          casLongNameOverride || formatMessage({ id: 'text.crewAlertingSystem' })
        }`;
        break;
      case 'Other':
        displayText = `${otherNameOverride || 'Other'}`;
        break;
      default:
        displayText = '';
        break;
    }
    return displayText;
  };

  const filterBookItems = (searchInputValue) => {
    if (!searchInputValue) {
      setFilteredItems(bookItems);
      return null;
    }

    if (bookItems.length === 0) {
      return null;
    }

    const lowerCaseSearchInputValue = searchInputValue.toLowerCase();
    const filteredBookItems = bookItems.filter((item) => {
      return Object.values(item).some((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(lowerCaseSearchInputValue);
        }

        if (typeof value === 'number') {
          return value.toString().includes(searchInputValue);
        }

        if (typeof value === 'object') {
          return Object.values(value).some((subValue) => {
            if (typeof subValue === 'string') {
              return subValue.toLowerCase().includes(lowerCaseSearchInputValue);
            }
            return false;
          });
        }
        return false;
      });
    });

    setFilteredItems(filteredBookItems);
    return null;
  };

  useEffect(() => {
    setMelItemsLoading(isMelItemsLoading);
  }, [isMelItemsLoading, setMelItemsLoading]);

  useEffect(() => {
    if (!defect?.deferred) {
      updateDefectData([{ value: 'Other', key: 'defect_type' }]);
    }
  }, [defect, updateDefectData]);

  useEffect(() => {
    if (melItems?.length > 0) {
      setAircraftHasMEL(true);
    }
  }, [melItems, setAircraftHasMEL]);

  useEffect(() => {
    if (defect?.defect_type === 'MEL' && melItems?.length > 0) {
      setBookItems(melItems);
    } else if (defect?.defect_type === 'CDL') {
      setBookItems([]);
    } else if (defect?.defect_type === 'NEF') {
      setBookItems([]);
    } else if (defect?.defect_type === 'CAS') {
      setBookItems([]);
    } else {
      setBookItems([]);
    }
  }, [defect?.defect_type, melItems]);

  useEffect(() => {
    if (searchInput) {
      filterBookItems(searchInput);
    } else {
      setFilteredItems(bookItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookItems, searchInput]);

  return (
    <Wrapper id="DeferralOptions" data-testid="StyledCard--DeferralOptions">
      <FlexWrapper column marginBottom={20}>
        <>
          <FlexWrapper marginBottom={30}>
            <FlexWrapper column marginRight={80}>
              <FlexWrapper>
                <Label marginBottom={20} fontWeight={600} identifier="DeferralOptions" marginRight={4}>
                  {formatMessage({ id: 'text.deferralOptions' })}
                </Label>
                <TFTooltip>{buildTooltipText()}</TFTooltip>
              </FlexWrapper>
              <FlexWrapper identifier="DeferralOptionsWrapper">{buildDeferralOptionsRadioButtons()}</FlexWrapper>
            </FlexWrapper>
          </FlexWrapper>
          <Label marginBottom={15} fontWeight={500}>
            {displayDefectTypeLabel()}
          </Label>
          {defect?.defect_type ? (
            <MelItemsWrapper>
              <Loading loading={melItemsLoading} contain />
              {bookItems && bookItems.length > 0 ? (
                <>
                  <SearchWrapper>
                    <Search
                      onClear={(): void => setSearchInput('')}
                      onChange={(e): void => setSearchInput(e.currentTarget.value.toLowerCase())}
                    />
                  </SearchWrapper>
                  <Card id="MELItemWrapper" maxHeight="500px" identifier="MelItemsWrapper">
                    <DisplayBookItems
                      melItems={filteredItems}
                      updateDefectData={updateDefectData}
                      handleMelItemChange={handleLocalMelItemChange}
                      defect={defect}
                      searchInput={searchInput}
                    />
                  </Card>
                  <FlexWrapper column={isMobile} marginTop={30} marginBottom={30}>
                    <FlexWrapper marginRight={20}>
                      <DateTimePicker
                        dateTime={defect?.deferred_at}
                        headerDate="Deferral date"
                        headerTime="Deferral time"
                        handleDateTimeChange={(value) => handleDeferralTimeChange(value, defect, updateDefectData)}
                        disabled={!unlockDeferralTime}
                      />
                    </FlexWrapper>
                    <FlexWrapper marginTop={isMobile ? 20 : 0} width="100%">
                      <DeferUntil defect={defect} formChanged={formChanged} />
                      <ButtonWrapper>
                        {!unlockDeferralTime ? (
                          <Button
                            primary={false}
                            height="24px"
                            padding="0 12px"
                            onClick={(): void => setUnlockDeferralTime(true)}
                          >
                            <StyledIcon src={padlockIcon} alt="padlock icon" />
                            Unlock
                          </Button>
                        ) : null}
                      </ButtonWrapper>
                    </FlexWrapper>
                  </FlexWrapper>
                  {defect?.mel_item && !isMobile && (
                    <>
                      {showRichTextMel ? (
                        <ViewNewLayoutMelDetails defect={defect} isMelTableVisible />
                      ) : (
                        <ViewMelDetails defect={defect} isMelTableVisible edit />
                      )}
                    </>
                  )}
                </>
              ) : (
                <ManuallyEnterMELDetails
                  defect={defect}
                  setDateDue={setDateDue}
                  noMEL
                  apuInstalled={apuInstalled}
                  updateDefectData={updateDefectData}
                  aircraftId={aircraftId}
                  formChanged={formChanged}
                />
              )}
            </MelItemsWrapper>
          ) : null}
        </>
      </FlexWrapper>
    </Wrapper>
  );
};

export default DeferralOptions;
