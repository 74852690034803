import React, { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Defect } from '../../../models/defects';
import { Flight } from '../../../models/flights';
import { DashboardState } from '../../../models';
import getOperatorSetting from '../../../utils/getOperatorSetting';
import BackButton from '../../BackButton';
import { handleBackButtonClick } from '../../../models/drawer';
import TFDropdown from '../../TFDropdown/TFDropdown';
import { hasAircraftPermission } from '../../_utils/AuthenticationWrapper';
import { AircraftPermission, AircraftResource } from '../../../models/aircraft';
import DateTimePicker from '../../DateTimePicker/DateTimePicker';
import { displayThirdPartyReferences, useDrawerBanner } from './utils';

interface EditDefectDrawerHeaderProps {
  defectData: Defect;
  aircraftId: string;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  flightsData: Flight[];
  isFetchingFlights: boolean;
  setAircraftId: (id: string) => void;
}

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 20px;
`;

const HeaderInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
  @media (max-width: 781px) {
    flex-wrap: wrap;
  }
`;

const EditDefectDrawerHeader: React.FC<EditDefectDrawerHeaderProps> = ({
  defectData,
  aircraftId,
  updateDefectData,
  flightsData,
  isFetchingFlights,
  setAircraftId,
}) => {
  const {
    drawer: { drawerHistory, mode },
    aircraft: { aircraftMap },
    userSettings,
  } = useSelector((state: DashboardState) => state);

  const [aircraftOptions, setAircraftOptions] = useState([]);
  const [chosenAircraft, setChosenAircraft] = useState(null);
  const [chosenFlight, setChosenFlight] = useState(null);
  const [flightOptions, setFlightOptions] = useState([]);

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { id } = useParams<{ id: string }>();

  const defectRename = getOperatorSetting('defect_name', id);

  const displayDefectEditHeader = (): ReactElement => {
    if (mode === 'add') {
      return <span>{formatMessage({ id: 'text.addDefect' }, { field: defectRename })}</span>;
    }
    return (
      <span>
        {defectRename}: <strong>{defectData?.number}</strong>
      </span>
    );
  };

  const handleChangeOfAircraft = (option: { title: string; colour?: string; value?: string }): void => {
    setChosenAircraft(option);
    setAircraftId(option.value);
  };

  const handleChangeOfFlight = (option: { title: string; colour?: string; value?: string }): void => {
    setChosenFlight(option);
    updateDefectData([{ value: option.value, key: 'flight_id' }]);
  };

  useEffect(() => {
    if (document.location.pathname === '/defects') {
      const createAircraftOptions = (values): void => {
        const newAircraftOptions = [];
        values.forEach((aircraft) => {
          const displayName = {
            title: `${aircraft?.registration} (${aircraft?.aircraft_type.designator})`,
            value: aircraft.id,
          };
          newAircraftOptions.push(displayName);
        });
        newAircraftOptions.sort((a, b) => a.title.localeCompare(b.title));
        setAircraftOptions(newAircraftOptions);
        if (mode === 'add') {
          const [selectAircraft = newAircraftOptions[0]] = newAircraftOptions.filter(
            (item) => item.value === aircraftId,
          );
          setChosenAircraft(selectAircraft);
          setAircraftId(selectAircraft.value);
        }
      };
      if (aircraftMap && Array.from(aircraftMap.values()).length > 0) {
        const values = Array.from(aircraftMap.values()).filter((item) => {
          const usersPermissions = userSettings?.details?.people?.find((person) =>
            person?.permission_groups[1]?.aircraft.includes(item?.id),
          );
          return hasAircraftPermission(
            item,
            AircraftResource.AIRCRAFT,
            AircraftPermission.READ,
            usersPermissions?.permission_groups[1],
          );
        });
        createAircraftOptions(values);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aircraftId, aircraftMap]);

  useEffect(() => {
    if (flightsData?.length > 0) {
      const titleArray = flightsData
        .filter((flight) => flight.departure_airport && (flight.arrival_airport || flight.actual_arrival_airport))
        .map((flight) => {
          return {
            title: `${flight.departure_airport}${
              flight.time_takeoff !== null ? ` (${moment(flight.time_takeoff).utc().format('HH:mm')})` : ''
            } - ${flight.arrival_airport || flight.actual_arrival_airport}${
              flight.time_landing !== null ? ` (${moment(flight.time_landing).utc().format('HH:mm')})` : ''
            } ${flight?.trip?.date}`,
            value: flight.id,
          };
        });
      titleArray.unshift({ title: 'None', value: null });
      setFlightOptions(titleArray);
      const foundFlight = titleArray.find((flight) => flight.value === defectData?.flight_id);
      setChosenFlight(foundFlight);
    }
  }, [flightsData, defectData?.flight_id]);

  return (
    <div>
      <HeaderWrapper>
        {drawerHistory?.length ? <BackButton onClick={() => dispatch(handleBackButtonClick())} /> : null}
        <Header>{displayDefectEditHeader()}</Header>
        {displayThirdPartyReferences(defectData)}
      </HeaderWrapper>
      {useDrawerBanner(defectData)}
      {document.location.pathname === '/defects' ? (
        <HeaderInfoWrapper>
          <TFDropdown
            initialValue={chosenAircraft}
            options={aircraftOptions}
            onSelect={handleChangeOfAircraft}
            id="EditDefectDrawerHeader-AircraftDropdown"
            label="Aircraft"
          />
        </HeaderInfoWrapper>
      ) : null}
      <HeaderInfoWrapper>
        <TFDropdown
          initialValue={chosenFlight}
          options={flightOptions}
          onSelect={handleChangeOfFlight}
          id="EditDefectDrawerHeader-FlightDropdown"
          label="Flight"
          disabled={isFetchingFlights}
        />
        <DateTimePicker
          headerDate="Date Recorded"
          headerTime="Time Recorded"
          handleDateTimeChange={(newDate) => updateDefectData([{ value: newDate, key: 'raised_at' }])}
          dateTime={defectData?.raised_at || moment.utc().toISOString()}
          noFuture
        />
      </HeaderInfoWrapper>
    </div>
  );
};

export default EditDefectDrawerHeader;
