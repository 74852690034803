import { DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DashboardState } from '../../models';
import calendarIcon from '../../assets/icon-small-calendar.svg';
import { Header } from '../CommonStyledComponents/CommonStyledComponents';
import styles from './DateTimePicker.module.less';

interface DateTimePickerProps {
  dateTime: string;
  headerDate: string;
  headerTime: string;
  handleDateTimeChange: (value: string) => void;
  width?: string;
  noFuture?: boolean;
  timeWidth?: string;
  disabled?: boolean;
}

const Wrapper = styled.div`
  display: flex;
`;

const DateSegment = styled.div`
  margin-right: 20px;
  @media (max-width: 450px) {
    max-width: 140px;
  }
`;

const TimeSegment = styled.div`
  max-width: ${({ timeWidth }): string => (timeWidth ? `${timeWidth}` : '100px')};
  @media (max-width: 450px) {
    max-width: 128px;
  }
`;

const ZSpan = styled.span`
  margin: 8px 16px 0 8px;
  @media (max-width: 450px) {
    margin: 8px 0 0 8px;
  }
`;

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  dateTime,
  headerDate,
  headerTime,
  handleDateTimeChange,
  width,
  noFuture,
  timeWidth,
  disabled,
}) => {
  const { dateFormat } = useSelector((state: DashboardState) => state.userSettings);

  const [dateTimeMoment, setDateTimeMoment] = useState<moment.Moment | undefined>();
  const [date, setDate] = useState<string>();
  const [time, setTime] = useState<string>();

  const handleDateChange = (newDate): void => {
    const checkTime = time || '00:00:00';
    const newDateTime = moment.utc(`${newDate} ${checkTime}`, `${dateFormat} HH:mm:ss`);
    setDateTimeMoment(newDateTime);
    handleDateTimeChange(newDateTime.toISOString());
  };

  const handleTimeChange = (newTime): void => {
    const checkDate = date || moment().utc().format(dateFormat);
    const newDateTime = moment.utc(`${checkDate} ${newTime}`, `${dateFormat} HH:mm:ss`);
    setDateTimeMoment(newDateTime);
    handleDateTimeChange(newDateTime.toISOString());
  };

  const disabledDate = (current): any => {
    if (noFuture) return current && current > moment();
    return null;
  };

  const disableHours = (): number[] => {
    if (moment(date, `${dateFormat}`).format('DD-MM-YYYY') === moment().utc().format('DD-MM-YYYY')) {
      let nextHour = parseInt(moment().utc().format('HH'), 10) + 1;
      const hoursArray: number[] = [];

      for (nextHour; nextHour < 24; nextHour += 1) {
        hoursArray.push(nextHour);
      }
      if (noFuture) return hoursArray;
    }
    return null;
  };

  const disableMinutes = (): number[] => {
    if (moment(date, `${dateFormat}`).format('DD-MM-YYYY') === moment().utc().format('DD-MM-YYYY')) {
      let nextMinute = parseInt(moment().utc().format('mm'), 10) + 1;
      const minuteArray: number[] = [];
      for (nextMinute; nextMinute < 60; nextMinute += 1) {
        minuteArray.push(nextMinute);
      }

      if (noFuture) return minuteArray;
    }
    return null;
  };

  useEffect(() => {
    if (dateTime) {
      setDateTimeMoment(moment.utc(dateTime));
    } else {
      setDateTimeMoment(undefined);
    }
  }, [dateFormat, dateTime]);

  useEffect(() => {
    if (dateTimeMoment) {
      setDate(moment(dateTimeMoment).utc().format(dateFormat));
      setTime(moment(dateTimeMoment).utc().format('HH:mm:ss'));
    }
  }, [dateFormat, dateTimeMoment]);

  return (
    <Wrapper data-test="DateTimePicker" data-testid="DateTimePicker--Wrapper">
      <DateSegment>
        <Header marginBottom="4px" editable data-testid="DateTimePicker--DateHeader">
          {headerDate}
        </Header>
        <DatePicker
          format={dateFormat}
          value={dateTimeMoment}
          onChange={(dateMoment, dateString): void => handleDateChange(dateString)}
          suffixIcon={<img src={calendarIcon} alt="calendarIcon" />}
          placeholder="-"
          className={width ? styles.datePicker : styles.datePickerSmall}
          allowClear={false}
          disabledDate={disabledDate}
          disabled={disabled}
        />
      </DateSegment>
      <TimeSegment timeWidth={timeWidth}>
        <Header marginBottom="4px" editable data-testid="DateTimePicker--TimeHeader">
          {headerTime}
        </Header>
        <Wrapper>
          <TimePicker
            value={dateTimeMoment}
            onChange={(timeMoment, timeString): void => handleTimeChange(timeString)}
            suffixIcon={<div />}
            placeholder="-"
            className={styles.timePicker}
            allowClear={false}
            format="HH:mm"
            disabledHours={disableHours}
            disabledMinutes={disableMinutes}
            disabled={disabled}
          />
          <ZSpan>z</ZSpan>
        </Wrapper>
      </TimeSegment>
    </Wrapper>
  );
};

export default DateTimePicker;
