import React from 'react';
import { Defect } from '../../../models/defects';
import { Flight } from '../../../models/flights';
import ViewDefectDrawerHeader from './ViewDefectDrawerHeader';

interface ViewDefectDrawerProps {
  defectData: Defect;
  aircraftId: string;
  updateDefectData: (changes: { value: any; key: string }[]) => void;
  flightsData: Flight[];
  isFetchingFlights: boolean;
}

const ViewDefectDrawer: React.FC<ViewDefectDrawerProps> = ({
  defectData,
  aircraftId,
  updateDefectData,
  flightsData,
  isFetchingFlights,
}) => {
  return (
    <div>
      <ViewDefectDrawerHeader
        defectData={defectData}
        aircraftId={aircraftId}
        updateDefectData={updateDefectData}
        flightsData={flightsData}
        isFetchingFlights={isFetchingFlights}
      />
    </div>
  );
};

export default ViewDefectDrawer;
